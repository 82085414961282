import { useEffect, useRef, RefObject } from 'react';
import { AnswerCreateSelectedChoicesInner } from 'generated-api';

export function normalizeString(subject: string) {
  if (!subject) {
    return '';
  }
  return subject
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export const sortByName = (a: { name: string }, b: { name: string }) => {
  const left = normalizeString(a.name);
  const right = normalizeString(b.name);
  return (left > right) ? 1 : (left === right ? 0 : -1);
}

export const sortByTitle = (a: { title: string }, b: { title: string }) => {
  const left = normalizeString(a.title);
  const right = normalizeString(b.title);
  return (left > right) ? 1 : (left === right ? 0 : -1);
}

export const shorten = (limit: number) => (v?: string): string | undefined => {
  return v && v.length > limit ? v.substring(0, limit) + '...' : v;
}

export const getRemainingItems = (
  selectedChoices: AnswerCreateSelectedChoicesInner[],
  itemsToMatch: string[],
  setItemsToMatch: (itemsToMatch: string[]) => void
) => {
  const alreadyMatchedItems = selectedChoices
    .map((selectedChoice: AnswerCreateSelectedChoicesInner) => {
      return selectedChoice.payload;
    })
    .flat();
  const remainingItemsToMatch = itemsToMatch.filter((itemToMatch: string) => {
    return !alreadyMatchedItems.includes(itemToMatch);
  });

  setItemsToMatch(remainingItemsToMatch);
};

export const useIsMount = () => {
  const isMountRef = useRef(false);
  useEffect(() => {
    isMountRef.current = true;
  }, []);
  return isMountRef.current;
};

export function useOutsideClick<TRef extends HTMLElement>(
  ref: RefObject<TRef>,
  callback: () => void
) {
  useEffect(() => {
    function handleOutsideClick(e: MouseEvent) {
      const node = e.target as Node;
      if (node && !ref?.current?.contains(node)) {
        callback();
      }
    }

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [callback, ref]);

  // logic for clicking outside of element into iframe
  useEffect(() => {
    const el = ref.current;
    // Prevent attaching multiple listeners
    if ((window as any).listenerOnClick) return;
    if (!el) return;

    window.focus();
    window.addEventListener('blur', () => {
      callback();
    });
    (window as any).listenerOnClick = true;

    return () => {
      (window as any).listenerOnClick = false;
      window.removeEventListener('blur', () => {
        callback();
      });
    };
  }, [callback, ref]);
}
