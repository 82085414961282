import React from 'react';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import {createHashRouter, RouterProvider} from 'react-router-dom';
import {ToastContainer} from 'components/ToastContainer';
import {store} from '../index';
import {AppBridgeEventPageMount} from '../model/appBridge';
import {AppProviders} from './AppProviders';
import {ContentViewerPage} from 'pages/ContentViewer/ContentViewerPage';
import {CodebooksPage} from 'pages/CodebooksPage';
import {NotFoundPage} from 'pages/ContentViewer/404';
import {BillingPage} from 'pages/BillingPage';
import {fetchAuthUser} from '../store/auth';
import ErrorBoundary from 'components/ErrorBoundary';
import {EditorTestPage} from "pages/EditorTest/EditorTestPage";
import {CataloguesPage} from "pages/CataloguesPage";
import {PublicOfferPage} from "pages/PublicOfferPage";
import {PrivateOfferPage} from "pages/PrivateOfferPage";

function App({appEvent}: { appEvent: AppBridgeEventPageMount }) {
  // useEffect(() => {
  //   console.log('React page mounted', appEvent.page);
  //   return () => console.log('React page UNmounted', appEvent.page);
  // }, [appEvent]);

  // fetch active user on react screens
  store.dispatch(fetchAuthUser());

  const router = createHashRouter([
    {
      path: 'learn/:courseId',
      element: <ContentViewerPage/>,
      children: [
        {
          path: 'certificate',
          element: <ContentViewerPage/>,
        },
        {
          path: 'lesson/:lessonId',
          element: <ContentViewerPage/>,
          children: [
            {
              path: 'step/:stepId',
              element: <ContentViewerPage/>,
            },
          ],
        },
      ],
    },
    {
      path: 'editor/:courseId/step/:stepId',
      element: <EditorTestPage/>,
    },
    {
      path: 'csPreferences/codebooks',
      element: <CodebooksPage/>,
    },
    {
      path: 'csPreferences/billing',
      element: <BillingPage/>,
    },
    {
      path: 'catalogues',
      element: <CataloguesPage/>,
      children: [
        {
          path: ':catalogueId',
          element: <CataloguesPage/>,
          children: [
            {
              path: ':pageType',
              element: <CataloguesPage/>,
              children: [
                {
                  path: ':pageId',
                  element: <CataloguesPage/>,
                },
              ]
            }
          ]
        },
      ]
    },
    {
      path: 'contentManager',
      element: appEvent.data?.type === 'private' ? <PrivateOfferPage/> : <PublicOfferPage/>,
      children: [
        {
          path: ':contentId',
          element: appEvent.data?.type === 'private' ? <PrivateOfferPage/> : <PublicOfferPage/>,
          children: [
            {
              path: 'create-offer',
              element: appEvent.data?.type === 'private' ? <PrivateOfferPage/> : <PublicOfferPage/>,
            }
          ]
        },
      ]
    },
    {
      path: 'notFound',
      element: <NotFoundPage/>,
    },
  ]);
  return (
    <ErrorBoundary>
      <AppProviders store={store}>
        <DndProvider backend={HTML5Backend}>
          <ToastContainer/>
          <RouterProvider router={router}/>
        </DndProvider>
      </AppProviders>
    </ErrorBoundary>
  );
}

export default App;
