import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Grid, Tooltip} from "@mui/material";
import {TextFormField} from "components/form/TextFormField";
import {useAppDispatch} from "store";
import {useAppTranslation} from "services/i18n";
import {createCatalogueQuestion, deleteCatalogueQuestion, fetchCatalogueQuestions} from "store/catalogues";
import {
  ContentEditorQuestionCataloguesQuestionCreateResponse,
  ContentEditorQuestionCataloguesQuestionIndexResponse,
  QuestionCatalogueQuestionCreate
} from "generated-api";
import {QuestionEditForm} from "components/catalogue/QuestionEditForm";
import {
  createCol,
  DataGrid,
  DataGridCol,
  DataGridMode,
  DataGridState,
  isSearchWithinSubject,
  ItemsState,
  OrderDirType
} from "components/DataGrid";
import {DeleteForever, Edit} from "@mui/icons-material";
import {ContextMenu, ContextMenuAction} from "components/ContextMenu";
import {datetimeToGui} from "../../helpers/date";
import {useModal} from "services/modal";
import {useNavigate} from "react-router-dom";
import {shorten} from "utils/utils";

interface QuestionType extends ContentEditorQuestionCataloguesQuestionIndexResponse {

}

interface GridFilter {
  search?: string;
}

const filterCallback = (filter: GridFilter, item: QuestionType): boolean => {
  return isSearchWithinSubject(filter.search, item.name);
};

const defaultState: DataGridState<QuestionType, GridFilter> = {
  orderCol: 'name',
  orderDir: OrderDirType.ASC,
  filter: {
    search: '',
  },
  page: 1,
  pageSize: 10,
  filterCallback,
};

interface Props {
  catalogueId: number;
  questionId?: number;
  onActionButtons: (actions?: JSX.Element) => void;
}

export const QuestionList = (props: Props) => {
  const {catalogueId, questionId, onActionButtons} = props;

  const dispatch = useAppDispatch();
  const t = useAppTranslation();
  const modal = useModal();
  const navigate = useNavigate();

  const [dataGridState, setDataGridState] = useState(defaultState);
  const [itemsState, setItemsState] = useState<ItemsState<QuestionType>>({items: [], isLoading: true});

  const [newQuestionType, setNewQuestionType] = useState<string | undefined>();

  const handleFetchItems = useCallback(async () => {
    setItemsState(s => ({...s, isLoading: true}));

    const items = (await dispatch(fetchCatalogueQuestions({
      term: dataGridState.filter.search,
      questionCatalogueId: catalogueId,
      page: dataGridState.page,
      perPage: dataGridState.pageSize,
      sort: dataGridState.orderCol ? dataGridState.orderCol + ':' + (dataGridState.orderDir || 'asc') : undefined
    })))?.payload as QuestionType[];
    setItemsState({items, isLoading: false});
    return items;

  }, [dataGridState, catalogueId, dispatch]);

  const handleCloseForm = useCallback(() => {
    navigate('/catalogues/' + catalogueId + '/questions');
  }, [catalogueId, navigate]);

  const handleQuestionRemove = useCallback(async (question: QuestionType) => {
    if (!catalogueId) {
      return;
    }
    const result = await modal.confirm({
      title: t('catalogues.questions.delete.action'),
      message: t('catalogues.questions.delete.message'),
      confirmText: t('catalogues.questions.delete.button')
    });
    if (result !== 'CONFIRM') {
      return;
    }

    await dispatch(deleteCatalogueQuestion({
      questionCatalogueId: catalogueId,
      id: '' + question.id,
    }));
    await handleFetchItems();

  }, [catalogueId, handleFetchItems, dispatch, modal, t]);

  const cols: DataGridCol<QuestionType>[] = useMemo(() => [
    createCol(t('catalogues.questions.table.name'), 'name', shorten(100), '40%'),
    // createCol(t('catalogues.questions.table.testCount'), ''),
    createCol(t('catalogues.questions.table.categoryCount'), 'categories_count', (v, item) => {
      return <Tooltip
        title={item.question_catalogue_categories?.map(c => c.title).join(', ')}><span>{v}</span></Tooltip>;
    }),
    createCol(t('catalogues.questions.table.publishedAt'), 'published_at', datetimeToGui),
    createCol(t('catalogues.questions.table.createdAt'), 'created_at', datetimeToGui),
    createCol(t('catalogues.questions.table.updatedAt'), 'updated_at', datetimeToGui),
    // createCol(t('catalogues.questions.table.categoryCount'), ''),
  ], [t]);

  const newQuestionActions = useMemo(() => {
    return [
      {title: t('editorTest.questionType.options.single'), callback: () => setNewQuestionType('single')},
      {title: t('editorTest.questionType.options.multiple'), callback: () => setNewQuestionType('multiple')},
      {title: t('editorTest.questionType.options.matching'), callback: () => setNewQuestionType('matching')}
    ] as ContextMenuAction[];

  }, [setNewQuestionType, t]);

  const createFilter = useCallback((formProps: any) => {
    return <Grid container spacing={4} className={'tw-pb-16'}>
      <Grid item md={6} xs={12}>
          <TextFormField name={'search'}
                         label={t('catalogues.questions.searchTitle')}
                         placeholder={t('catalogues.questions.searchPlaceholder')}
          />
      </Grid>
    </Grid>;
  }, [t]);

  const handleActionEdit = useCallback((q: QuestionType) => {
    navigate('/catalogues/' + catalogueId + '/questions/' + q.id);
  }, [catalogueId, navigate])

  const actions = useMemo(() => [
    {
      title: 'catalogues.questions.delete.action',
      icon: <DeleteForever/>,
      callback: handleQuestionRemove,
    },
    {
      title: 'form.update',
      icon: <Edit/>,
      callback: handleActionEdit,
    },
  ], [handleActionEdit, handleQuestionRemove]);

  useEffect(() => {
    if (!newQuestionType) {
      return;
    }
    const q: QuestionCatalogueQuestionCreate = {
      hint: "",
      name: "",
      point_value: 1,
      question_type: newQuestionType,
      response_type: "text",
      tolerance: 0,
    };

    dispatch(createCatalogueQuestion({
      questionCatalogueId: catalogueId,
      body: q
    })).then((res) => {
      const item = res?.payload as ContentEditorQuestionCataloguesQuestionCreateResponse;
      if (item.id) {
        navigate('/catalogues/' + catalogueId + '/questions/' + item.id);
      }
    });
    setNewQuestionType(undefined);

  }, [catalogueId, newQuestionType, navigate, dispatch]);

  useEffect(() => {
    if (!questionId) {
      handleFetchItems().then();
    }
  }, [questionId, handleFetchItems]);

  if (questionId) {
    return <QuestionEditForm
      id={questionId}
      catalogueId={catalogueId}
      onSuccess={handleCloseForm}
      onCancel={handleCloseForm}
      onActionButtons={onActionButtons}
    />;
  }

  return <>
    <h3>{t('catalogues.questions.title')}</h3>
    <div className={'actions tw-align-middle tw-flex tw-mb-10'}>
      <ContextMenu title={t('catalogues.questions.add')} actions={newQuestionActions}
                   buttonProps={{color: 'primary', variant: 'contained'}}/>
    </div>
    <DataGrid
      createFilter={createFilter}
      cols={cols}
      state={dataGridState}
      defaultState={defaultState}
      setState={setDataGridState}
      itemsState={itemsState}
      mode={DataGridMode.SERVER}
      emptyListMessage={t('catalogues.questions.emptyList')}
      emptySearchMessage={t('catalogues.questions.emptySearch')}
      actions={actions}
      noTopBorder
      absoluteScroll
    />
  </>
}
