import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  CategoryApi,
  ChoiceApi,
  CompanySpacesUsersGetRequest,
  ContentEditorQuestionCataloguesIdGetRequest,
  ContentEditorQuestionCataloguesIdPutRequest,
  ContentEditorQuestionCataloguesPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdPutRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdDeleteRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdPutRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdDeleteRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdDeleteRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdGetRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdPutRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdDeleteRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPublishPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPurgeChoicesPostRequest,
  ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdValidationErrorsGetRequest,
  QuestionApi,
  QuestionCatalogueApi,
  UploadedFileApi,
  UserApi,
  ValidationErrorApi,
} from '../generated-api';
import {API_CONFIG} from '../app/api-config';

const questionCatalogueApi = new QuestionCatalogueApi(API_CONFIG);
const categoryApi = new CategoryApi(API_CONFIG);
const questionApi = new QuestionApi(API_CONFIG);
const choiceApi = new ChoiceApi(API_CONFIG);
const userApi = new UserApi(API_CONFIG);
const validationErrorApi = new ValidationErrorApi(API_CONFIG);
const uploadedFileApi = new UploadedFileApi(API_CONFIG);

export const fetchCatalogues = createAsyncThunk('catalogues/all', async (args: {}) => {
  return await questionCatalogueApi.contentEditorQuestionCataloguesGet();
});

export const fetchCatalogue = createAsyncThunk('catalogues/get', async (args: ContentEditorQuestionCataloguesIdGetRequest) => {
  return await questionCatalogueApi.contentEditorQuestionCataloguesIdGet(args);
});

export const createCatalogue = createAsyncThunk('catalogues/create', async (args: ContentEditorQuestionCataloguesPostRequest) => {
  return await questionCatalogueApi.contentEditorQuestionCataloguesPost(args);
});

export const updateCatalogue = createAsyncThunk('catalogues/update', async (args: ContentEditorQuestionCataloguesIdPutRequest) => {
  return await questionCatalogueApi.contentEditorQuestionCataloguesIdPut(args);
});

export const fetchCatalogueAuthors = createAsyncThunk('catalogues/authors', async (args: CompanySpacesUsersGetRequest) => {
  return await userApi.companySpacesUsersGet(args);
});

export const fetchCatalogueCategories = createAsyncThunk('catalogueCategories/all', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesGetRequest) => {
  return await categoryApi.contentEditorQuestionCataloguesQuestionCatalogueIdCategoriesGet(args);
});

export const fetchCatalogueCategory = createAsyncThunk('catalogueCategories/get', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdGetRequest) => {
  return await categoryApi.contentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdGet(args);
});

export const createCatalogueCategory = createAsyncThunk('catalogueCategories/create', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesPostRequest) => {
  return await categoryApi.contentEditorQuestionCataloguesQuestionCatalogueIdCategoriesPost(args);
});

export const updateCatalogueCategory = createAsyncThunk('catalogueCategories/update', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdPutRequest) => {
  return await categoryApi.contentEditorQuestionCataloguesQuestionCatalogueIdCategoriesIdPut(args);
});

export const fetchCatalogueQuestions = createAsyncThunk('catalogueQuestions/all', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsGetRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsGet(args);
});

export const fetchCatalogueQuestion = createAsyncThunk('catalogueQuestions/get', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdGetRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdGet(args);
});

export const createCatalogueQuestion = createAsyncThunk('catalogueQuestions/create', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsPostRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsPost(args);
});

export const updateCatalogueQuestion = createAsyncThunk('catalogueQuestions/update', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdPutRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdPut(args);
});

export const deleteCatalogueQuestion = createAsyncThunk('catalogueQuestions/delete', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdDeleteRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsIdDelete(args);
});

export const fetchCatalogueChoices = createAsyncThunk('catalogueChoices/all', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesGetRequest) => {
  return await choiceApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesGet(args);
});

export const fetchCatalogueChoice = createAsyncThunk('catalogueChoices/get', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdGetRequest) => {
  return await choiceApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdGet(args);
});

export const createCatalogueQuestionChoice = createAsyncThunk('catalogueChoices/create', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesPostRequest) => {
  return await choiceApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesPost(args);
});

export const updateCatalogueQuestionChoice = createAsyncThunk('catalogueChoices/update', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdPutRequest) => {
  return await choiceApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdPut(args);
});

export const deleteCatalogueQuestionChoice = createAsyncThunk('catalogueChoices/delete', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdDeleteRequest) => {
  return await choiceApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesIdDelete(args);
});

export const purgeCatalogueQuestionChoices = createAsyncThunk('catalogueChoices/purge', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPurgeChoicesPostRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPurgeChoicesPost(args);
});

export const publishCatalogueQuestion = createAsyncThunk('catalogueQuestions/publish', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPublishPostRequest) => {
  return await questionApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdPublishPost(args);
});


export const validateCatalogueQuestion = createAsyncThunk('catalogueQuestions/validate', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdValidationErrorsGetRequest) => {
  return await validationErrorApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdValidationErrorsGet(args);
});

export const linkCatalogueQuestionFile = createAsyncThunk('catalogueQuestionFiles/link', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdPostRequest) => {
  return await uploadedFileApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdPost(args)
});

export const deleteCatalogueQuestionFile = createAsyncThunk('catalogueQuestionFiles/delete', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdDeleteRequest) => {
  return await uploadedFileApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdFilesIdDelete(args);
});

export const linkCatalogueQuestionChoiceFile = createAsyncThunk('catalogueChoiceFile/link', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdPostRequest) => {
  return await uploadedFileApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdPost(args)
});

export const deleteCatalogueQuestionChoiceFile = createAsyncThunk('catalogueChoiceFile/delete', async (args: ContentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdDeleteRequest) => {
  return await uploadedFileApi.contentEditorQuestionCataloguesQuestionCatalogueIdQuestionsQuestionIdChoicesChoiceIdFilesIdDelete(args);
});