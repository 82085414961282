import * as React from "react";
import {useState} from "react";
import {Box, Paper, styled, Tab, Tabs} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index} = props;

    return value === index ? <Paper sx={{p: 3, boxShadow: "unset", borderRadius: 0, border: "1px solid #F5F5F5", flexGrow: "1"}}>
        {children}
    </Paper> : null;
}

const StyledTabs = styled(Tabs)({
    backgroundColor: '#F5F5F5',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

interface StyledTabProps {
    label: string;
    disabled?: boolean;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: "56px",
        fontSize: "16px",
        padding: "24px",
        '&.Mui-selected': {
            color: 'inherit',
            fontWeight: theme.typography.fontWeightMedium,
            background: '#fff'
        },
        '&:hover': {
            color: 'inherit',
            background: '#fff'
        },
        '&:Mui-focusVisible': {
            color: 'inherit',
            background: '#fff'
        },
    }),
);

export type PageTab = {
    label: string,
    content: () => JSX.Element,
    disabled?: boolean
}

export type PageTabsProps = {
    tabs: PageTab[]
}

export const PageTabs = ({tabs}: PageTabsProps) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Box sx={{paddingLeft: "1px", flexGrow: "1", display: "flex", flexDirection: "column"}}>
        <StyledTabs value={value} onChange={handleChange}>
            {tabs.map((tab, i) => {
                return <StyledTab label={tab.label} key={i} disabled={!!tab.disabled}/>
            })}
        </StyledTabs>
        {tabs.map((tab, i) => {
            return <TabPanel key={i} value={value} index={i}>
                {value === i ? tab.content() : null}
            </TabPanel>
        })}
    </Box>;
}
