import format from 'date-fns/format';
import localeCS from 'date-fns/locale/cs';
import localeEN from 'date-fns/locale/en-US';
import {differenceInCalendarDays} from "date-fns";

const dateLocales: {[lng: string]: any} = {
	cs: localeCS,
	en: localeEN,
};

let locale: Locale = dateLocales.en;

export function setDateLocale(lng: string) {
  locale = dateLocales[lng] || dateLocales.en;
}

export function dateToGui(val: Date | string | undefined) {
    if (!val) {
        return null;
    }
    const v: Date = typeof val === 'string' ? new Date(val) : val;
    return format(v, 'P', {locale}); // 'd.M.yyyy'
}

export function datetimeToGui(val: Date | undefined) {
  if (!val || val.getFullYear() <= 1970) {
    return null;
  }
  return format(val, 'P p', { locale }); // 'd.M.yyyy HH:mm'
}

export function timeToGui(val: Date | undefined) {
  if (!val) {
    return null;
  }
  return format(val, 'p', { locale }); // 'HH:mm'
}

export function timeVerboseToGui(val: number) {
    if (!val) {
        return null;
    }
    return (val > 60
            ? Math.floor(val / 60) + ' h'
            : '0 h')
        + ((val % 60) > 0
            ? ' ' + (val % 60) + ' min'
            : '')
}

export function getDayOfWeekName(dow: number) {
  return locale.localize?.day(dow === 7 ? 0 : dow);
}

export function dateDiff(a: Date | string | undefined, b: Date | string | undefined) {
	if (!a || !b) {
		return null;
	}
	const da: Date = typeof a === 'string' ? new Date(a) : a;
	const db: Date = typeof b === 'string' ? new Date(b) : b;
	return differenceInCalendarDays(db, da);
}
