import {useAppTranslation} from "services/i18n";
import {Alert, Box, Grid, Tooltip} from "@mui/material";
import {
    CalendarMonthOutlined,
    Check,
    CheckCircleOutlined,
    DescriptionOutlined,
    Edit,
    Help,
    PlayCircleOutlined,
    Visibility
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {dateToGui, timeVerboseToGui} from "../../helpers/date";
import * as React from "react";
import {useCallback, useState} from "react";
import {Content, Offer, Version} from "pages/PublicOfferPage";
import {OfferCompletionDialog} from "components/offer/OfferCompletionDialog";
import {UserBasicInfo} from "generated-api";

const DESCRIPTION_CHAR_MIN = 500;

const statusBannerSx = {
    '& .MuiAlert-message': {
        width: '100%',
        alignItems: 'center',
        fontSize: '16px',
        paddingLeft: 0,
        paddingRight: 0
    },
    '& .MuiAlert-icon': {alignItems: 'center'},
    '&.MuiAlert-standardSuccess': {
        paddingLeft: 0,
        paddingRight: 0,
        color: 'var(--color-black-text)',
        background: 'transparent',
        '& svg': {
            color: 'var(--color-black-text)'
        }
    }
}

const BoxLabel = ({title, tooltip}: { title: string, tooltip?: string }) => {
    return <label>
        {title}
        {!!tooltip && <Tooltip title={<div>
            {!!title && <strong>{title}</strong>}
            <div dangerouslySetInnerHTML={{__html: tooltip}}/>
        </div>}>
            <Help/>
        </Tooltip>}
    </label>
}

const OfferStatusBanner = ({isActive, onToggleStatus}: { isActive: boolean, onToggleStatus: () => void }) => {
    const t = useAppTranslation();

    return <Alert severity={isActive ? 'success' : 'warning'} className={'tw-flex'} sx={statusBannerSx}
        icon={isActive ? <Visibility/> : undefined}>
        <Grid container className={'tw-items-center'}>
            <Grid item className={'tw-grow'}>
                <div
                    dangerouslySetInnerHTML={{__html: isActive ? t('offer.public.state.active') : t('offer.public.state.inactive')}}/>
            </Grid>
            <Grid item>
                <Button color={isActive ? 'dark' as any : 'primary'} variant={'contained'} disabled={false} onClick={onToggleStatus}>
                    {isActive ? t('offer.public.state.deactivate.button') : t('offer.public.state.activate.button')}
                </Button>
            </Grid>
        </Grid>
    </Alert>;
}

const roundButtonStyle = {
    borderRadius: '50%',
    aspectRatio: '1',
    minWidth: '28px',
    display: 'block',
    '& > svg': {
        verticalAlign: 'top'
    }
}

const completionCheckStyle = {
    ...roundButtonStyle,
    '& svg': {
        color: 'var(--color-black-text)',
    }
}

const ComponentEditButton = ({isSuccess, isLoading, onClick}: {
    isSuccess?: boolean,
    isLoading: boolean,
    onClick: () => void
}) => {
    return <Button variant={'contained'} color={isSuccess ? 'success' : 'warning'} onClick={onClick} sx={roundButtonStyle} disabled={isLoading}>
        <Edit/>
    </Button>
}

const guarantorButtonSuccess = {
    color: 'var(--color-text-text)',
    background: 'var(--color-success)',
    borderRadius: '8px',
    boxShadow: 'none',
    width: 'auto',
    display: 'inline-block',
    padding: '6px 12px',
    fontSize: '14px',
    '& + div': {
        marginLeft: '10px'
    }
}

const guarantorButtonWarning = {
    ...guarantorButtonSuccess,
    background: 'var(--color-warning)'
}

const GuarantorButton = ({guarantor, isSuccess}: {
    guarantor: UserBasicInfo,
    isSuccess?: boolean
}) => {
    const t = useAppTranslation();

    return <Tooltip title={
        <div dangerouslySetInnerHTML={{__html: isSuccess ? t('offer.guarantors.profileFilled') : t('offer.guarantors.profileEmpty')}}/>}>
        <Box sx={isSuccess ? guarantorButtonSuccess : guarantorButtonWarning}>{guarantor.full_name}</Box>
    </Tooltip>;
}

const CompletionCircle = ({progress, size, stroke, handleModalOpen}: {
    progress: number,
    size: number,
    stroke: number,
    handleModalOpen: () => void
}) => {
    if (progress >= 100) {
        return <Button variant={'contained'} color={'success'} onClick={handleModalOpen} sx={completionCheckStyle}>
            <Check/>
        </Button>
    }
    const angle = 3.6 * progress;
    const half = size / 2;
    const radius = half - stroke / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeOffset = (1 / 4) * circumference;
    const strokeDasharray = (angle / 360) * circumference;

    return <svg onClick={handleModalOpen} style={{
        width: size + 'px',
        height: size + 'px',
        stroke: 'var(--color-success)',
        strokeWidth: stroke + 'px',
        fill: 'transparent'
    }} className={'hover:tw-opacity-75'}>
        <circle cx={half} cy={half} r={radius} style={{stroke: '#eee'}}></circle>
        <circle cx={half} cy={half} r={radius}
            strokeDasharray={strokeDasharray + "," + (circumference - strokeDasharray)}
            strokeDashoffset={strokeOffset}></circle>
        <text x="50%" y="50%" textAnchor="middle" fill="var(--color-success)" dy=".25em" style={{
            fontSize: (size / 4) + 'px',
            strokeWidth: '1px',
        }}>{Math.round(progress)}&thinsp;%
        </text>
    </svg>
}

const completionBannerStyle = {
    '& > .tw-flex': {
        fontSize: '16px',
        color: 'var(--color-black-text)',
        alignItems: 'center',
        '&:not(:last-of-type)': {
            paddingRight: '14px',
        },
        'svg + span': {
            paddingLeft: '6px'
        }
    }
}

const OfferCompletionBanner = ({offer, version}: { offer: Offer, version: Version }) => {
    const t = useAppTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = useCallback(() => {
        setModalOpen(true);
    }, []);

    const progress = offer.evaluation?.score_ratio || 0;
    const isComplete = progress >= 100;

    return <Grid container className={'tw-items-center'} columnSpacing={1} sx={completionBannerStyle}>
        <Grid item>
            <div className={'tw-p-10 tw-pt-14 tw-pb-14 tw-cursor-pointer'}>
                <CompletionCircle progress={progress} size={56} stroke={6} handleModalOpen={handleModalOpen}/>
            </div>
        </Grid>
        <Grid item className={'tw-grow tw-pt-0'}>
            <strong style={isComplete ? {color: 'var(--color-success)'} : undefined} onClick={handleModalOpen} className={'tw-cursor-pointer hover:tw-opacity-75'}
            >{!isComplete ? t('offer.completion.title') : t('offer.completion.completeTitle')}</strong>
            <p className={'tw-m-0'}>
                {t('offer.completion.description')}
                {!isComplete &&
                    <span> <u className={'tw-cursor-pointer hover:tw-opacity-75'} onClick={handleModalOpen}>{t('offer.completion.hintLink')}</u>.</span>}
            </p>
        </Grid>
        <Grid item className={'tw-flex'}>
            <Tooltip title={t('offer.createdAt.help')}>
                <div className={'tw-flex tw-items-center'}><CalendarMonthOutlined/>
                    <span>{dateToGui(version.created_at)}</span></div>
            </Tooltip>
        </Grid>
        <Grid item className={'tw-flex'}>
            <Tooltip title={t('offer.versionNumber.help')}>
                <div className={'tw-flex tw-items-center'}><DescriptionOutlined/>
                    <span>{t('offer.content.version')} {version.meta.number}</span></div>
            </Tooltip>
        </Grid>
        <Grid item className={'tw-flex'}>
            <Tooltip title={t('offer.updatedAt.help')}>
                <div className={'tw-flex tw-items-center'}><CheckCircleOutlined/>
                    <span>{dateToGui(version.updated_at)}</span></div>
            </Tooltip>
        </Grid>
        {modalOpen && <OfferCompletionDialog offer={offer} onClose={() => setModalOpen(false)}/>}
    </Grid>;
}

const offerSx = {
    '& h3': {
        fontSize: '44px',
        margin: '0 0 14px'
    },
    '& label': {
        color: 'var(--color-black-text)',
        fontSize: '14px',
        '& > svg': {
            color: 'var(--color-black-text)',
            fontSize: '16px',
            marginLeft: '4px',
            verticalAlign: 'middle'
        },
        '& + div': {
            marginTop: '10px'
        }
    },
    '& .MuiAlert-root': {
        borderRadius: '8px',
        width: '100%'
    },
    '& .MuiAlert-message': {
        padding: '14px 10px',
        width: '100%',
        '& > strong, & .MuiGrid-root > strong': {
            fontSize: '18px',
            color: 'var(--color-black)'
        },
        '& p': {
            margin: '0',
            color: 'var(--color-black-text)',
            '& > span:not(.tw-w-150) + span:before': {
                content: '", "'
            }
        },
        '& strong + p': {
            margin: '10px 0 0 0',
        },
    },
    '& .copy': {
        fontSize: '18px'
    }
}

export const PublicOffer = (
    {
        offer, content,
        onPriceEdit, onToggleStatus, onDescriptionEdit, onCategoriesEdit, onPromoVideoEdit,
        isLoading
    }: {
        offer: Offer,
        content: Content,
        onPriceEdit: () => void,
        onToggleStatus: () => void,
        onDescriptionEdit: () => void,
        onCategoriesEdit: () => void,
        onPromoVideoEdit: () => void,
        isLoading: boolean
    }) => {
    const t = useAppTranslation();

    // const handleEdit = useCallback(() => {
    //     window.location.href = "#/editor/" + content.draft_version?.id;
    // }, [content.draft_version?.id])

    const version = content.active_version! as Version;
    const charCount = offer.description?.replaceAll(/<\/?[a-z ]+>/gm, '')?.length || 0;
    const components = offer.evaluation?.components;

    return <Grid container sx={offerSx} columnSpacing={10}>
        <Grid item xs={12}>
            <OfferStatusBanner isActive={offer.is_active} onToggleStatus={onToggleStatus}/>
        </Grid>
        <Grid item xs={12}>
            <OfferCompletionBanner offer={offer} version={version}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <h3>{version.meta.name}</h3>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.promo.title')} tooltip={t('offer.promo.help')}/>
                    <Box className={'tw-relative'}>
                        {offer.promo_video_url
                            ? <iframe src={offer.promo_video_url} width="100%" height="100%" frameBorder="0" style={{minHeight: '438px'}} title={t('offer.promo.iframeTitle')}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                            : <img src={version.meta.promo_image_url} alt={version.meta.name} width={'100%'}
                                className={'tw-rounded-xl'}/>}
                        <Tooltip placement={'left'}
                            title={<div className={'tw-flex tw-items-center'}>
                                <PlayCircleOutlined/>&nbsp;&nbsp;{t('offer.promo.edit')}</div>}>
                            <div className={'tw-absolute'} style={{right: '26px', bottom: '26px'}}>
                                <ComponentEditButton isSuccess={components?.promo_video_url} isLoading={isLoading} onClick={onPromoVideoEdit}/>
                            </div>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity={components?.marketplace_description ? 'success' : 'warning'} icon={false}>
                        <Grid container>
                            <Grid item className={'tw-grow'}>
                                <strong>{t('offer.descriptionHelp.title')}</strong>
                                <p dangerouslySetInnerHTML={{
                                    __html: t('offer.descriptionHelp.charCount', {
                                        charCount,
                                        charMin: DESCRIPTION_CHAR_MIN
                                    })
                                }}/>
                            </Grid>
                            <Grid item className={'tw-flex tw-items-center'}>
                                <ComponentEditButton isSuccess={components?.marketplace_description} isLoading={isLoading} onClick={onDescriptionEdit}/>
                            </Grid>
                        </Grid>
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.description.title')} tooltip={t('offer.description.help')}/>
                    <div dangerouslySetInnerHTML={{__html: offer.description}} className={'copy'}>
                    </div>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.price.title')} tooltip={t('offer.price.help')}/>
                    <Alert severity={'success'} icon={false}>
                        <Grid container>
                            <Grid item className={'tw-grow'}>
                                <strong>{offer.price ? t('offer.price.head', {price: offer.price}) : t('offer.price.free')}</strong>
                                {offer.discounts?.map((d) => <p key={d.id}>
                            <span className={'tw-inline-block tw-w-150'}>
                                {t('offer.price.discountLicence', {count: d.license_count})}
                            </span>
                                    <span>
                                {t('offer.price.discountPrice', {price: Math.round(offer.price * (100 - d.discount_percentage) / 100)})}
                            </span>
                                </p>)}
                            </Grid>
                            <Grid item className={'tw-flex tw-items-center'}>
                                <ComponentEditButton isSuccess={true} isLoading={isLoading} onClick={onPriceEdit}/>
                            </Grid>
                        </Grid>
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.categories.title')} tooltip={t('offer.categories.help')}/>
                    <Alert severity={components?.categories ? 'success' : 'warning'} icon={false}>
                        <Grid container spacing={2}>
                            <Grid item className={'tw-grow'}>
                                {!!content.categories?.length
                                    ? <>
                                        <strong>{t('offer.categories.head', {count: content.categories.length})}</strong>
                                        <p>{content.categories.map((c) => <span key={c.id}>{c.name}</span>)}</p>
                                    </>
                                    : <strong
                                        className={'tw-pt-14 tw-pb-14 tw-inline-block'}>{t('offer.categories.empty')}</strong>}
                            </Grid>
                            <Grid item className={'tw-flex tw-items-center'}>
                                <ComponentEditButton isSuccess={components?.categories} isLoading={isLoading} onClick={onCategoriesEdit}/>
                            </Grid>
                        </Grid>
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.guarantors.title')} tooltip={t('offer.guarantors.help')}/>
                    <div>
                        {!!version.guarantors?.length
                            ? <>
                                <div>{version.guarantors.map((g, i) => {
                                    const isSuccess = content?.filledGuarantorIds && content.filledGuarantorIds.indexOf(g.id) >= 0;
                                    return <GuarantorButton key={i} guarantor={g} isSuccess={isSuccess}/>
                                })}</div>
                            </>
                            : <Alert severity={'warning'} icon={false}>
                                <span className={'tw-inline-block'}>{t('offer.guarantors.empty')}</span>
                            </Alert>}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.competences.title')} tooltip={t('offer.competences.help')}/>
                    <Alert severity={!!components?.competences ? 'success' : 'warning'} icon={false}>
                        {!!offer.content_competences?.length
                            ? <p>
                                {offer.content_competences.map((c) =>
                                    <span key={c.id}>{c.competence_name} - {t('offer.competences.level')} {c.competence_level}</span>)}
                            </p>
                            : <span className={'tw-inline-block'}>{t('offer.competences.empty')}</span>}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.timeEstimation.title')} tooltip={t('offer.timeEstimation.help')}/>
                    <Alert severity={!!version.time_estimation ? 'success' : 'warning'} icon={false}>
                        {!!version.time_estimation
                            ? <span>{timeVerboseToGui(version.time_estimation)}</span>
                            : <span className={'tw-inline-block'}>{t('offer.timeEstimation.empty')}</span>}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <BoxLabel title={t('offer.preview.title')}/>
                    <Box sx={{width: '240px'}}>
                        <img src={version.meta.promo_image_url} width={'240px'} alt={version.meta.name}
                            style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}/>
                        <div style={{height: '5px'}} className={'bg-' + content.content_type}></div>
                        <Grid container style={{
                            background: '#eee',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            padding: '20px'
                        }}>
                            <Grid item xs={12}>
                                <h4>{version.meta.name}</h4>
                            </Grid>
                            <Grid item xs={8}>

                            </Grid>
                            <Grid item xs={4}>
                                {offer.price ? <strong>{offer.price} Kč</strong> :
                                    <strong>{t('offer.price.free')}</strong>}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}
