import {Formik, FormikErrors, useFormikContext} from 'formik';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {fetchCatalogue, fetchCatalogueAuthors, updateCatalogue} from '../../store/catalogues';
import {Button, Grid, LinearProgress} from '@mui/material';
import {TextFormField} from '../form/TextFormField';
import {
    ContentEditorQuestionCatalogueShowResponse,
    type ContentInCompanySpaceUpgradeResponseAuthorsInner,
    QuestionCatalogueUpdate
} from "generated-api";
import {SelectFormField} from "components/form/SelectFormField";
import {OptionValue} from "model/form";
import {isApiResultError} from '../../helpers/api';

interface Props {
    id: number;
    onActionButtons: (actions?: JSX.Element) => void;
    onCancel: () => void;
    onSuccess: () => void;
}

interface FormFieldsProps extends Props {
    isSaving: boolean;
}

interface AuthorType extends ContentInCompanySpaceUpgradeResponseAuthorsInner {

}

const createAuthorOption = (a: AuthorType) => ({
    value: a.id,
    label: a.full_name,
    tooltip: a.email,
    icon: <img alt={a.full_name} style={{width: '24px', borderRadius: '50%', marginRight: '8px'}}
               src={a.small_picture_url || a.picture_url}/>
});

export const CatalogueFormFields = ({currentAuthors}: { currentAuthors?: AuthorType[] }) => {

    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const {values: {author_ids}} = useFormikContext();

    const [authors, setAuthors] = useState<OptionValue[] | undefined>(undefined);
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);

    const knownAuthors = useRef<OptionValue[]>(currentAuthors?.map(createAuthorOption) || []);

    const fetchAuthors = useCallback(async () => {
        setIsLoading(true);
        const items = (await dispatch(fetchCatalogueAuthors({filterFulltext: search})))?.payload as any[];
        const authors: OptionValue[] = items?.map(createAuthorOption);
        knownAuthors.current.forEach((ca) => {
            if (!authors?.find((a) => a.value === ca.value)) {
                authors?.unshift(ca);
                return;
            }
        });
        setAuthors(authors);
        setIsLoading(false);

    }, [dispatch, search]);

    useEffect(() => {
        author_ids?.forEach((id: number) => {
            if (!knownAuthors.current.find((ka) => ka.value === id)) {
                const author = authors?.find((a) => a.value === id);
                if (author) {
                    knownAuthors.current.push(author);
                }
            }
        })

    }, [author_ids, authors]);

    useEffect(() => {
        fetchAuthors().then();
    }, [fetchAuthors]);

    if (authors === undefined) {
        return <LinearProgress/>;
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextFormField name="title" label={t('catalogues.form.title')} maxlength={100}/>
        </Grid>
        <Grid item xs={12}>
            <SelectFormField name='author_ids' label={t('catalogues.form.authors')}
                             placeholder={t('catalogues.form.authorAdd')} isMulti={true}
                             options={authors} showTooltip onSearch={setSearch} isLoading={isLoading}/>
        </Grid>
    </Grid>;
}

const CatalogueForm = (props: FormFieldsProps) => {
    const {onActionButtons, onCancel, isSaving} = props;

    const t = useAppTranslation();
    const {handleSubmit, initialValues} = useFormikContext();

    const formSubmit = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    useEffect(() => {
        onActionButtons(<>
            <Button color={'inherit'} variant={'outlined'} disabled={isSaving} onClick={onCancel}>
                {t('catalogues.form.back')}
            </Button>
            <Button color={'inherit'} variant={'contained'} disabled={isSaving} onClick={formSubmit}>
                {t('catalogues.form.saveChanges')}
            </Button>
        </>);
        return () => {
            onActionButtons(undefined);
        }
    }, [onActionButtons, formSubmit, onCancel, isSaving, t]);

    return <form onSubmit={handleSubmit}>
        <CatalogueFormFields currentAuthors={(initialValues as any).authors}/>
    </form>;
}

export const CatalogueEditForm = (props: Props) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [catalogue, setCatalogue] = useState<QuestionCatalogueUpdate | undefined>();

    const {id, onSuccess} = props;
    const handleFetchItem = useCallback(async () => {
        const res = (await dispatch(fetchCatalogue({id: '' + id})))?.payload as ContentEditorQuestionCatalogueShowResponse;
        if (res) {
            setCatalogue({...res, author_ids: res.authors?.map(a => a.id) || []} as QuestionCatalogueUpdate);
        }

    }, [id, dispatch]);

    const handleSaveItem = useCallback(async (values: QuestionCatalogueUpdate) => {
        setIsSaving(true);
        const result = await dispatch(updateCatalogue({
            id: '' + id,
            body: values
        }));
        setIsSaving(false);

        if (!isApiResultError(result)) {
            onSuccess();
        }

    }, [id, onSuccess, dispatch]);

    const handleValidate = useCallback((values: QuestionCatalogueUpdate) => {
        let errors = {} as FormikErrors<QuestionCatalogueUpdate>;
        if (!values.title) {
            errors.title = t('catalogues.form.error.titleRequired');
        }
        return errors;
    }, [t]);

    useEffect(() => {
        handleFetchItem().then();
    }, [handleFetchItem, t]);

    if (!catalogue) {
        return <LinearProgress/>;
    }

    return (
        <Formik
            initialValues={catalogue}
            onSubmit={handleSaveItem}
            validate={handleValidate}
        >
            <CatalogueForm isSaving={isSaving} {...props}/>
        </Formik>
    );
};
