import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {ArrowDownward, ArrowUpward, MoreVert, Search} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {useAppTranslation} from '../services/i18n';
import {TextFormField} from './form/TextFormField';
import {Formik, FormikProps} from 'formik';
import {normalizeString} from "utils/utils";
import CircularProgress from "@mui/material/CircularProgress";

export const enum DataGridMode {
  CLIENT = 'client',
  SERVER = 'server',
}

export const enum OrderDirType {
  ASC = 'asc',
  DESC = 'desc',
}

export type DataGridCol<E> = {
  [K in keyof E]: {
    title: string | JSX.Element
    col: K,
    orderCol?: K | null,
    renderValue?: (value: E[K], item: E) => React.ReactNode,
    width?: number | string,
    align?: 'left' | 'right' | 'center'
  }
}[keyof E]

export function createCol<E, K extends keyof E>(
  title: string | JSX.Element,
  col: K,
  renderValue?: (value: E[K], item: E) => React.ReactNode,
  width?: number | string
): DataGridCol<E> {
  return {
    title,
    col,
    orderCol: col,
    renderValue,
    width
  } as DataGridCol<E>
}

export function alignCenter<E>(col: DataGridCol<E>): DataGridCol<E> {
  col.align = 'center';
  return col;
}

export interface DataGridItemAction<E> {
  title: string;
  icon: JSX.Element;
  isApplicable?: (item: E) => boolean;
  callback: (item: E) => unknown;
}

export interface DataGridState<E, F> {
  orderCol: keyof E;
  orderDir: OrderDirType;
  filter: F;
  filterCallback?: (filter: F, item: E) => boolean;
  page?: number;
  pageSize?: number;
}

export interface ItemsState<E> {
  isLoading: boolean;
  items: Array<E>;
}

export type DataGridProps<E, F> = {
  cols: Array<DataGridCol<E>>;
  state: DataGridState<E, F>;
  defaultState?: DataGridState<E, F>;
  setState: (state: DataGridState<E, F>) => void;
  itemsState: ItemsState<E>;
  emptyListMessage: string | JSX.Element;
  emptySearchMessage?: string | JSX.Element;
  createFilter?: (formProps: FormikProps<F>) => JSX.Element;
  actions?: DataGridItemAction<E>[];
  isActionMenu?: boolean;
  mode?: DataGridMode;
  noTopBorder?: boolean;
  absoluteScroll?: boolean;
  rowClass?: (item: E) => string | undefined;
};

export const isSearchWithinSubject = (search: string | undefined, subject: string): boolean => {
  if (!search) {
    return true;
  }
  if (!subject) {
    return false;
  }
  const normalized = normalizeString(subject);
  return normalized.indexOf(normalizeString(search)) >= 0;
}

const DataGridItemActions = <E, >(props: {
  actions?: DataGridItemAction<E>[];
  isActionMenu?: boolean;
  item: E;
}) => {
  const t = useAppTranslation();
  const [openMenuItem, setMenuOpenItem] = useState<{ item: E; anchorEl: HTMLElement } | null>(null);

  const {actions, isActionMenu, item} = props;

  if (!actions || !(actions.length > 0)) {
    return null;
  }

  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>, item: E) => {
    setMenuOpenItem({item, anchorEl: e.currentTarget});
  };
  const handleMenuClose = () => {
    setMenuOpenItem(null);
  };

  if (isActionMenu) {
    return (
      <TableCell>
        <IconButton onClick={(e) => handleMenuOpen(e, item)}>
          <MoreVert/>
        </IconButton>
        <Menu
          open={openMenuItem ? openMenuItem.item === item : false}
          anchorEl={openMenuItem?.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleMenuClose}
        >
          {actions.map((action, i) => {
            if (action.isApplicable && !action.isApplicable(item)) {
              return null;
            }
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  action.callback(item);
                  setMenuOpenItem(null);
                }}
              >
                {action.icon}
                <span>{t(action.title)}</span>
              </MenuItem>
            );
          })}
        </Menu>
      </TableCell>
    );
  } else {
    return (
      <TableCell className={'data-grid-actions'}>
        {actions.map((action, i) => {
          if (action.isApplicable && !action.isApplicable(item)) {
            return null;
          }
          return (
            <Tooltip key={i} title={t(action.title)}>
              <IconButton
                color={'secondary'}
                onClick={() => {
                  action.callback(item);
                }}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          );
        })}
      </TableCell>
    );
  }
};

export const DataGrid = <E, F>(props: DataGridProps<E, F>) => {
  const {
    cols,
    state,
    defaultState,
    setState,
    createFilter,
    itemsState: {items, isLoading},
    actions,
    noTopBorder,
    absoluteScroll,
    rowClass
  } = props;

  const t = useAppTranslation();

  const [pendingPageSizeForLoading, setPendingPageSizeForLoading] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleFilter = useCallback((filter: F) => {
    setState({...state, filter, page: 1, pageSize: defaultState?.pageSize});
  }, [setState, state, defaultState]);

  const handleLoadMore = useCallback(() => {
    setPendingPageSizeForLoading(items.length);
    setState({...state, pageSize: items.length + (defaultState?.pageSize || 10)});
  }, [state, items.length, defaultState?.pageSize, setState]);

  useEffect(() => {
    if (!isLoading && isLoadingMore && items.length >= pendingPageSizeForLoading) {
      setPendingPageSizeForLoading(0);
      setIsLoadingMore(false);
    }
  }, [isLoading, isLoadingMore, pendingPageSizeForLoading, items.length, state.pageSize]);

  useEffect(() => {
    if (pendingPageSizeForLoading > 0 && isLoading) {
      setIsLoadingMore(true);
    }
  }, [isLoading, pendingPageSizeForLoading]);

  if (!items?.length && isLoading) {
    return (
      <div className={'grid-loading-data'}>
        <LinearProgress/>
      </div>
    );
  }

  let rows;
  if (props.mode !== DataGridMode.SERVER) {
    rows = items
      .slice()
      .filter((item) => {
        if (state.filter && state.filterCallback) {
          return state.filterCallback(state.filter, item);
        }
        return true;
      })
      .sort((a: E, b: E) => {
        if (a === b) {
          return 0;
        }
        if (state.orderCol) {
          const va = a[state.orderCol];
          const vb = b[state.orderCol];
          if ((va as any)?.localeCompare) {
            if ((va as any).localeCompare(vb) > 0) {
              return state.orderDir === OrderDirType.DESC ? -1 : 1;
            } else {
              return state.orderDir === OrderDirType.DESC ? 1 : -1;
            }
          }
          if (va > vb) {
            return state.orderDir === OrderDirType.DESC ? -1 : 1;
          } else {
            return state.orderDir === OrderDirType.DESC ? 1 : -1;
          }
        }
        return 0;
      });
  } else {
    rows = items;
  }
  const isFilterUsed = defaultState?.filter && JSON.stringify(defaultState.filter) !== JSON.stringify(state.filter);

  return (
    <>
      <div className={props.mode !== DataGridMode.SERVER ? 'data-grid-filter' : ''}>
        <Formik initialValues={state.filter} onSubmit={handleFilter}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              {createFilter ? createFilter(props) : <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFormField
                    name="search"
                    type={'search'}
                    onBlur={props.submitForm}
                    label={
                      <>
                        <Search/> {t('common.search')}
                      </>
                    }
                    maxlength={100}
                  />
                </Grid>
              </Grid>}
            </form>
          )}
        </Formik>
      </div>
      <LinearProgress hidden={!isLoading}/>
      <TableContainer className={absoluteScroll ? 'tw-relative tw-flex tw-flex-col tw-grow' : undefined}>
        <Table size={'small'} stickyHeader className={absoluteScroll ? 'tw-absolute' : 'tw-relative'}>
          <TableHead>
            <TableRow sx={noTopBorder ? {'& > .MuiTableCell-head': {borderTop: 'unset'}} : undefined}>
              {cols.map((col, i) => {
                return (
                  <TableCell
                    key={i}
                    className={col.orderCol ? 'data-grid-head-sortable' : undefined}
                    width={col.width}
                    align={col.align}
                    onClick={() => {
                      if (!col.orderCol) {
                        return;
                      }
                      if (col.orderCol === state.orderCol) {
                        setState({
                          ...state,
                          page: 1,
                          pageSize: defaultState?.pageSize,
                          orderDir:
                            state.orderDir === OrderDirType.ASC
                              ? OrderDirType.DESC
                              : OrderDirType.ASC,
                        });
                      } else {
                        setState({
                          ...state,
                          page: 1,
                          pageSize: defaultState?.pageSize,
                          orderDir: OrderDirType.DESC,
                          orderCol: col.orderCol,
                        });
                      }
                    }}
                  >
                    {col.title}
                    {state.orderCol === col.orderCol ? (
                      state.orderDir === OrderDirType.ASC ? (
                        <ArrowUpward/>
                      ) : (
                        <ArrowDownward/>
                      )
                    ) : null}
                  </TableCell>
                );
              })}
              {actions && actions.length && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          {rows.length > 0 && (
            <TableBody>
              {rows.map((item, i) => {
                return (
                  <TableRow key={i} className={rowClass ? rowClass(item) : undefined}>
                    {cols.map((col, j) => {
                      let v;
                      if (col.renderValue) {
                        v = col.renderValue(item[col.col], item);
                      } else {
                        v = item[col.col];
                      }
                      return <TableCell key={j} align={col.align}>{v as any}</TableCell>;
                    })}
                    <DataGridItemActions {...props} item={item}/>
                  </TableRow>
                );
              })}
              {((!!state.pageSize && rows.length >= state.pageSize) || !!pendingPageSizeForLoading) && <TableRow>
                  <TableCell colSpan={cols.length} onClick={!!pendingPageSizeForLoading || isLoading ? undefined : handleLoadMore}
                             className={'tw-text-center tw-p-10 tw-pb-20'}>
                      <Button color={'primary'} variant={'contained'} disabled={!!pendingPageSizeForLoading || isLoading}>
                        {!!pendingPageSizeForLoading ? <CircularProgress size={24} /> : t('common.loadMore')}
                      </Button>
                  </TableCell>
              </TableRow>}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {rows.length <= 0 && <div
          className={'data-grid-zero-data'}>{isFilterUsed ? props.emptySearchMessage : props.emptyListMessage}</div>}
    </>
  );
};
