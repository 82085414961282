import {createTheme} from '@mui/material';
import {PaletteOptions} from "@mui/material/styles/createPalette";

export const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

let createThemeFromVars = () => createTheme({
  palette: {
    primary: {
      main: cssVar('--color-primary'),
      contrastText: cssVar('--color-primary-contrast'),
    },
    secondary: {
      main: cssVar('--color-secondary'),
    },
    success: {
      main: cssVar('--color-success'),
      contrastText: cssVar('--color-white'),
    },
    warning: {
      main: cssVar('--color-warning'),
      contrastText: cssVar('--color-white'),
    },
    error: {
      main: cssVar('--color-error'),
      contrastText: cssVar('--color-white'),
    },
    dark: {
      main: '#212121',
      light: '#595959',
      dark: '#595959',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  } as PaletteOptions,
  typography: {
    fontSize: 14,
    h5: {
      fontSize: 20,
    },
  },
});

export const createAppTheme = () => {
  const theme = createThemeFromVars();
  return createTheme(theme, {
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            '& .MuiCardHeader-root': {
              backgroundColor: 'var(--color-black80)',
              color: 'var(--color-white)',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            },
            '& .MuiCardHeader-root .MuiButton-root:not(.MuiButton-contained)': {
              color: 'var(--color-white)',
            },
            '& .MuiCardHeader-root .MuiIconButton-root': {
              color: 'var(--color-white)',
            },
            '& .MuiCardHeader-root .MuiButton-containedInherit': {
              color: 'var(--color-black80)',
            },
            '& .MuiCardHeader-root .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiCardContent-root': {
              // borderTopRightRadius: 0,
              // borderTopLeftRadius: 0,
              padding: 0,
            },
            '& .MuiCardContent-root:last-child': {
              paddingBottom: 0,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '& .MuiButtonBase-root svg': {
              color: 'rgba(0,0,0,0.54)',
            },
            '& .MuiButtonBase-root svg + span': {
              marginLeft: '16px',
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-head': {
              borderTop: '2px solid rgba(0,0,0,.12)',
            },
            '& .MuiTableCell-head svg': {
              fontSize: '100%',
            },
            '& .MuiTableRow-root:last-child td': {
              borderBottom: 'none',
            },
            '& .MuiTableCell-root svg': {
              color: 'rgba(0,0,0,0.54)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedSuccess:disabled': {
              backgroundColor: 'var(--color-success)',
              color: 'var(--color-white)',
              opacity: .6
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-container': {
              justifyContent: 'flex-center',
              alignItems: 'flex-center',
            },
            '& .MuiPaper-root': {
              width: '100%',
              borderRadius: '16px',
            },
            '& .MuiDialogTitle-root': {
              backgroundColor: 'var(--color-black80)',
              color: 'var(--color-white)',
            },
            '& .MuiDialogActions-root': {
              padding: '14px'
            },
            'a': {
              color: 'var(--color-primary)',
              textDecoration: 'underline',
              '&:focus, &:hover': {
                color: 'var(--color-primary60)'
              }
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '&.MuiSnackbar-root': {
              position: 'static',
              top: 'auto',
            },
            '&.MuiSnackbar-root + .MuiSnackbar-root': {
              marginTop: '10px',
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            '&.MuiLinearProgress-root[hidden]': {
              background: 'transparent',
            },
            '&.MuiLinearProgress-root[hidden] > span': {
              display: 'none',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&': {
              display: 'flex',
              alignItems: 'center',
              color: '#595959', /* always? */
              '& > svg': {
                paddingLeft: '4px',
              },
            },
            '&.Mui-focused > .MuiFormLabel-asterisk': {
              color: 'rgb(232,55,18)'
            },
            '&.Mui-disabled': {
              color: '#595959', /* always? */
            }
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& input': {
              // .cmd-input
              padding: '2px 2px 1px',
              lineHeight: '26px',
              height: '30px',
            },
            '&:before': {
              borderBottomColor: 'rgba(0,0,0,0.12)',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            boxShadow: '0 0 12px 0 #0000001A',
            background: 'var(--color-white)',
            color: 'var(--color-black)',
            fontSize: '14px',
            lineHeight: '16px',
            padding: '12px',
            borderRadius: '8px',
            fontWeight: 'normal',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: '500px',
            '& > div > strong': {
              display: 'block',
              padding: '10px 12px 8px 12px',
              margin: '-12px -12px 12px -12px',
              background: 'var(--color-black80)',
              color: 'var(--color-white)',
              borderRadius: '8px 8px 0 0'
            }
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: 'var(--color-success-back)',
            color: 'var(--color-black)'
          },
          // standardError: {
          //   backgroundColor: 'var(--color-error-back)',
          //   color: 'white'
          // },
          standardWarning: {
            backgroundColor: 'var(--color-warning-back)',
            color: 'var(--color-black)'
          },
          // standardInfo: {
          //   backgroundColor: 'var(--color-info-back)',
          //   color: 'black'
          // }
        }
      },
    },
  });
}
